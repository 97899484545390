<svelte:options tag={null} />

<script lang="ts">
  import '@everymatrix/casino-footer-section';
  import '@everymatrix/panic-button';

  import { onMount } from 'svelte';
  import moment from 'moment';
  import { checkSession, isMobile, getDevice } from 'rvhelper';
  import { _, addNewMessages, setLocale, setupI18n } from './i18n';
  import { TRANSLATIONS } from './translations.js'

  export let endpoint:string = '';
  export let lang:string = 'en';
  export let env:string = '';
  export let clientstyling:string = '';
  export let clientstylingurl:string = '';
  export let translationurl:string = '';
  // Clock exposed attributes - If the clock is enabled, all attributes should be given a value, otherwise they can be ignored safely
  export let clockenabled:string = ''; // "true" to enable, anything to disable
  export let clocksecondsenabled:string = ''; // "true" to display seconds in our default format and to refresh the clock every 1000ms
  export let clockcustomformat:string = ''; // "false" for defaulting to our format
  export let clocktext:string = 'Time:'; // string preceding clock

  export let languageselectorenabled:string = 'true';
  export let languageslist:string = 'en, ro';
  export let languagedisplaytype = 'name';
  export let session:string = '';
  export let userid:string = '';
  export let userendpoint:string = '';
  export let panicbuttonenabled:string = 'true';
  export let sessiontimerenabled:string = 'true'; // 'true' to enable, anything to disable
  export let grouplink:string = 'true';
  export let baseurl:string = '';

  let userAgent:string = window.navigator.userAgent;
  let isOnMobile:boolean = isMobile(userAgent);
  let gameVendorsLinksTitle:string = '';
  let gameVendorsRepeater:Array<Object> = [];
  let paymentLinksTitle:string = '';
  let paymentMethodsRepeater:Array<Object> = [];
  let sponsorsLinksTitle:string = '';
  let sponsorsRepeater:Array<Object> = [];
  let helpLinksTitle:string = '';
  let helpLinksRepeater:Array<Object> = [];
  let licensesLinksTitle:string = '';
  let licensesRepeater:Array<Object> = [];
  let socialLinksTitle:string = '';
  let socialLinksRepeater:Array<Object> = [];
  let copyright:string = '';
  let licenseDesc:string = '';
  let hasErrors:boolean = false;
  let isLoading:boolean = false;
  let receivedFooterData:any;
  let headScript:boolean = false;
  let licenseScriptSrc:string = '';
  let licenseScript:string = '';
  let isLoggedIn:boolean = false;
  let sessionID:string = '';
  let playerID:string = '';
  let selectedLanguage:string = 'hr';
  let languages:Array<object> = [];

  let time:string;
  let refreshRate:number;

  let languagesArray:Array<string> = [];
  let languageDrawer:HTMLElement;
  let languageDisplayName:string = '';
  let currentLanguageFlag:string = '';
  let languagePackLoaded:boolean = false;

  let timeString:string = '';
  let clockInterval:any;
  let timerInterval:any;

  let customStylingContainer:HTMLElement;
  let sessionTimerElement:HTMLElement = document.createElement('p');
  let clockElement:HTMLElement = document.createElement('p');

  setupI18n({ withLocale: 'en', translations: {}});

  const setTranslationUrl = ():void => {
    let url:string = translationurl;

    fetch(url).then((res:any) => res.json())
    .then((res) => {
      Object.keys(res).forEach((item:any):void => {
        addNewMessages(item, res[item]);
      });
    }).catch((err:any) => {
      console.log(err);
    });
  }

  Object.keys(TRANSLATIONS).forEach((item:any) => {
    addNewMessages(item, TRANSLATIONS[item]);
  });

  let panicButtonCustomText:string = $_('casinoFooter.panicButton');

  const setSession = ():void => {
    checkSession(userendpoint, session).then((res:any) => {
      sessionID = res.Guid;
      playerID = res.UserID;
      isLoggedIn = true;
      setSessionTimer(res);
    }, (err:any) => {
      isLoggedIn = false;
      console.error('Error while checking the session - ', err)
    });
  }

  const setSessionTimer = (sessionData:any):void => {
    // using Date instead of moment because they recommend in their docs to stop using it for new projects
    const loginTime = new Date(sessionData.Login);
    const timezoneOffsetHours = new Date().getTimezoneOffset() / 60;
    let currentTime = new Date();
    let milisecondDifference = currentTime.getTime() - loginTime.getTime();

    let hours = Math.floor(milisecondDifference / 3600000) + timezoneOffsetHours;
    let minutes = Math.floor(milisecondDifference % 3600000 / 60000);
    let seconds = Math.floor(milisecondDifference % 60000 / 1000);

    timerInterval = setInterval(() => {
      hours = Math.floor(milisecondDifference / 3600000) + timezoneOffsetHours;
      minutes = Math.floor(milisecondDifference % 3600000 / 60000);
      seconds = Math.floor(milisecondDifference % 60000 / 1000);

      timeString = `${hours > 9 ? hours : '0' + hours}:${minutes > 9 ? minutes : '0' + minutes}:${seconds > 9 ? seconds : '0' + seconds}`;

      sessionTimerElement.innerHTML = ` ${$_('sessionTimer')}  ${timeString}`;

      milisecondDifference += 1000;
    }, 1000);
  }

  const refreshClock = ():void => {
    clockInterval = setInterval(() => {
      clockDisplay();
    }, refreshRate);
  }

  const clockDisplay = ():void => {
    const format = clockcustomformat != 'false' ? clockcustomformat : `hh:mm${clocksecondsenabled === "true" ? ':ss' : ''} (UTC Z)`;
    time = moment().format(format);

    clockElement.innerHTML = ` ${clocktext} ${time}`
  }

  const getCmsData = ():void => {
    let url:URL = new URL(`${endpoint}/${lang}/footer-raw-data`);
    
    let device = getDevice(userAgent);

    url.searchParams.append('env', env);

    if(device){
      if(device === 'PC'){
        url.searchParams.append('device', 'dk')
      } else if(device === 'iPad' || device === 'iPhone') {
        url.searchParams.append('device', 'mtWeb');  // replace with ios when we will have a native ios up for this
      } else {
        url.searchParams.append('device', 'mtWeb')
      }
    }

    if (grouplink == 'true') {
      url.searchParams.append('helpLinks_groupby', 'helpLinkCategory');
    }

    isLoading = true;

    fetch(url.href)
      .then((res:any) => res.json())
      .then((data:any) => {
        isLoading = false;
        receivedFooterData = data;
        let licenseHeadScriptSrc,
            licenseScriptBody,
            licenseEvent;
        ({
          gameVendorsLinksTitle,
          gameVendorsRepeater,
          paymentLinksTitle,
          paymentMethodsRepeater,
          sponsorsLinksTitle,
          sponsorsRepeater,
          helpLinksTitle,
          helpLinksRepeater,
          licensesLinksTitle,
          licensesRepeater,
          socialLinksTitle,
          socialLinksRepeater,
          copyright,
          licenseDesc,
          licenseHeadScriptSrc,
          licenseScriptBody,
          licenseEvent,
          language : languages
        } = receivedFooterData);

        if (languages) {
          languagePackLoaded = true;
          updateLanguageDisplayName();
        }

        if (licenseHeadScriptSrc?.length > 0) {
          headScript = true;
          licenseScriptSrc = licenseHeadScriptSrc;
        }

        if (licenseScriptBody?.length > 0) {
          licenseScript = licenseScriptBody;
        }

        window.postMessage({ type: licenseEvent }, window.location.href);

        window.postMessage({
          type: 'FooterData',
          gameVendorsRepeater,
          paymentMethodsRepeater,
          sponsorsRepeater,
          helpLinksRepeater,
          licensesRepeater,
          socialLinksRepeater
        }, window.location.href);
      }).catch((err:any) => {
        hasErrors = true;
        isLoading = false;

        console.error(err);
      });
  }

  const setContent = (element:HTMLElement, content:any):void => {
    let htmlContent = document.createElement("div");
    htmlContent.innerHTML = content;
    element.append(htmlContent);

    //Add clock
    if (clockenabled === 'true' && element.classList.contains('DetailedLicenses')) {

      element.children[0].children[0].append(clockElement);
    }
    //Add session timer
      if (sessiontimerenabled == 'true' && element.classList.contains('DetailedLicenses')) {

      element.children[0].children[0].append(sessionTimerElement);
    }
  }

  const changeLanguage = ():void => {
    window.postMessage({type: 'LanguageChanged', selectedLanguage}, window.location.href);
  };

  const toggleLanguageDrawer = ():void => {
    languageDrawer.classList.toggle('hidden');
  }

  const overlayOptionTrigger = (e:any):void => {
    let option = e.target.closest('.OverlayLanguageOption');

    selectedLanguage = option.getAttribute('data-lang');

    changeLanguage();
    updateLanguageDisplayName();

    languageDrawer.classList.toggle('hidden');
  };

  const updateLanguageDisplayName = ():void => {
    let matchFound = false;

    languages.forEach((l:any, i:number) => {
      if (l.code == selectedLanguage.toLowerCase()) {
        let langName:string = '';

        matchFound = true;
        currentLanguageFlag = l.flag_icon_editable;

        switch (languagedisplaytype) {
          case 'name':
            langName = l.name;
            break;

          case 'translatedName':
            langName = l.translated_name;
            break;

          default:
            langName = l.code;
            break;
        }

        languageDisplayName = langName;
      }
    });

    if (!matchFound) {
      languageDisplayName = selectedLanguage;
    }
  }

  const setLanguagesArray = ():void => {
    languagesArray = languageslist.replace(/ /g,'').split(',');
    languagesArray = languagesArray.map((language:string) => language.toUpperCase());
  }

  const setClientStyling = ():void => {
    let sheet = document.createElement('style');
    sheet.innerHTML = clientstyling;
    customStylingContainer.appendChild(sheet);
  }

  const setClientStylingURL = ():void => {
    let url:URL = new URL(clientstylingurl);
    let cssFile:HTMLElement = document.createElement('style');

    fetch(url.href)
      .then((res:any) => res.text())
      .then((data:any) => {
        cssFile.innerHTML = data;

        // after the first call is made for the custom styling file, save the css inside clientstyling in order to avoid multiple fetches inside subwidgets
        clientstyling = clientstyling ? clientstyling + data : data;

        setTimeout(() => { customStylingContainer.appendChild(cssFile) }, 1);
      });
  }

  const setActiveLanguage = ():void => {
    setLocale(lang);
    panicButtonCustomText = $_('casinoFooter.panicButton');
  }

  onMount(() => {
    return () => {
      clearInterval(clockInterval);
      clearInterval(timerInterval)
    };
  });

  $: lang && setActiveLanguage();
  $: languageslist && setLanguagesArray();
  $: session && userid && userendpoint && setSession();
  $: endpoint && lang && env && getCmsData();
  $: translationurl && setTranslationUrl();
  $: clockenabled !== 'false' && clocksecondsenabled && clockcustomformat && clockDisplay();
  $: clockenabled !== 'false' && clocksecondsenabled && (refreshRate = clocksecondsenabled == "true" ? 1000 : 60000) && refreshClock();
  $: lang && (selectedLanguage = lang) && languages && (languages.length > 0) && updateLanguageDisplayName();
  $: clientstyling && customStylingContainer && setClientStyling();
  $: clientstylingurl && customStylingContainer && setClientStylingURL();

</script>

<svelte:head>
  {#if headScript}
    <script src={licenseScriptSrc}></script>
  {/if}
</svelte:head>

{#if hasErrors}
  <p class="SearchLoading">500 Error - Internal Server Error</p>
{:else}
  {#if isLoading}
    <p class="SearchLoading">Loading ...</p>
  {:else}

  <div class="Footer" bind:this={customStylingContainer}>
    <div class="FooterSide {isOnMobile && isLoggedIn && panicbuttonenabled === "true" ? 'FooterSideMobile' : ''}">
      <!-- start helper section -->
        {#if helpLinksRepeater}
          {#each helpLinksRepeater as category}
            {#if category.key}
            <p class="FooterTitle">{category.key}</p>
            {/if}
            <casino-footer-section
              helperflag="1"
              {clientstyling}
              category={category.key}
              displaycolumn="true"
              {baseurl}
              {lang}
              footertype="footersecondary">
            </casino-footer-section>
          {/each}
        {/if}
    </div>

    <div class="FooterGrid">
      <!-- start vendors section -->
        {#if gameVendorsRepeater}
          <div class="GridRow">
            <div>
              {#if gameVendorsLinksTitle}
              <p class="FooterTitle">{gameVendorsLinksTitle}</p>
              {/if}
              <casino-footer-section
                vendorflag="1"
                {clientstyling}
                {baseurl}
                {lang}
                footertype="footersecondary">
              </casino-footer-section>
            </div>
          </div>
        {/if}
      <!-- end vendors section -->
      <!-- start payments section -->
      {#if paymentMethodsRepeater}
        <div class="GridRow">
          <div>
            {#if paymentLinksTitle}
              <p class="FooterTitle">{paymentLinksTitle}</p>
            {/if}
            <casino-footer-section
              paymentflag="1"
              {clientstyling}
              {baseurl}
              {lang}
              footertype="footersecondary">
            </casino-footer-section>
          </div>
        </div>
      {/if}
      <!-- end payments section -->

      <!-- start panic button -->
      {#if panicbuttonenabled === "true" && isLoggedIn}
        <panic-button
          customtext={panicButtonCustomText}
          alternativestyling="true"
          {lang}
          {clientstyling}
        ></panic-button>
      {/if}
      <!-- end panic button -->
      <!-- start license section -->
      {#if licensesRepeater}
        <div class="GridRow">
          <div class="LicenseLinks">
            {#if licensesLinksTitle}
            <p class="FooterTitle">{licensesLinksTitle}</p>
            {/if}
            <casino-footer-section
              licenseFlag="1"
              {clientstyling}
              {baseurl}
              {lang}
              footertype="footersecondary">
            </casino-footer-section>
          </div>
          <!-- start detailed licenses section -->
          <div class="LicenseDesc">
            {#if licenseDesc}
              <div class="DetailedLicenses" use:setContent={licenseDesc}></div>
              <div class="DetailedCustomLicenses" use:setContent={licenseScript}></div>
            {/if}
          </div>
          <!-- end detailed licenses section -->
        </div>
      {/if}
      <!-- end license section -->

      <!-- start sponsors section -->
      {#if sponsorsRepeater}
        <div class="GridRow">
          {#if sponsorsRepeater}
          <div>
            {#if sponsorsLinksTitle}
              <p class="FooterTitle">{sponsorsLinksTitle}</p>
            {/if}
            <casino-footer-section
              sponsorflag="1"
              {clientstyling}
              {baseurl}
              {lang}
              footertype="footersecondary">
            </casino-footer-section>
          </div>
          {/if}
        </div>
      {/if}
        <!-- end sponsors section -->

        <!-- start social section -->
        {#if socialLinksRepeater}
         <div class="GridRow">
            {#if socialLinksTitle}
              <p class="FooterTitle">{socialLinksTitle}</p>
            {/if}
            <casino-footer-section
              socialflag="1"
              {clientstyling}
              {baseurl}
              {lang}
              footertype="footersecondary">
            </casino-footer-section>
          </div>
        {/if}
        <!-- end social section -->

        <!-- start language selector -->
      {#if (languageselectorenabled==="true" && languagePackLoaded && selectedLanguage)}
        <div class="GridRow">
          <div class="LanguageSelectorContainer">
            <!-- traditional dropdown option for screenreader users. Works the same way as the old language selector. Display: none -->
            <select bind:value={selectedLanguage} class="Item ItemLanguage" on:change={()=>changeLanguage()}>
              {#each languagesArray as operatorLanguage}
                <option value={operatorLanguage} selected>{operatorLanguage}</option>
              {/each}
            </select>

            <!-- Custom dropdown in order to be able to display country flags -->
            <div class="LanguageSelectorOverlay">
              <div bind:this={languageDrawer} class="LanguageDrawer hidden">
                <ul id="LanguageList">
                  {#each languages as language}
                    <li class="OverlayLanguageOption" data-lang={language.code} on:click={overlayOptionTrigger}><img class="LanguageFlag" src={language.flag_icon_editable}><a href="javascript:void(0)"><span>{language.name}</span></a></li>
                  {/each}
                </ul>
              </div>
              <button class="DrawerButton" value="{selectedLanguage}" on:click={toggleLanguageDrawer}><img class="LanguageFlag" src={currentLanguageFlag}>{languageDisplayName}
                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M0.94 0L4.00067 3.05467L7.06 0L8 0.940667L4.00067 4.94133L0 0.940667L0.94 0Z" fill="black"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/if}
      <!-- end language selector -->

      {#if copyright}
      <!-- start copyright section -->
      <div class="CopyrightAreaRights CopyrightArea" use:setContent={copyright}></div>
      <!-- end copyright section -->
     {/if}

    </div>
  </div>
  {/if}
{/if}


<style lang="scss">
  :host {
    font-family: system-ui, -apple-system, Roboto, Helvetica;
  }
  $color-white : #E8E9EB;

  .SearchLoading {
    color: var(--emfe-w-color-white, #FFFFFF);
    text-align: center;
  }

  .Footer {
    position: relative;
    background-color: $color-white;
    display: flex;
    padding: 21px 12px;
    flex-direction: row;
  }

  .FooterSideMobile {
    margin-top: 44px;
  }

  .FooterSide {
    justify-content: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    .FooterTitle {
      font-weight: 500;
      font-size: 12px;
      color: #111;
      text-transform: uppercase;
      margin: 21px 12px;
    }
  }
  .FooterGrid {
    font-weight: 400;
    height: 100%;
    display: flex;
    max-width: 1200px;
    margin: auto;
    background-color: $color-white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .GridRow {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      align-items: flex-start;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      text-align: left;
      max-width: 1100px;
      p {
          display: inline;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          color: #111;
      }
     .LicenseDesc {
      flex: 1;
      max-width: 575px;
      line-height: 1.3;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .DetailedLicenses {
        font-size: 10px;
        color: var(--emfe-w-color-gray-150, #828282);
      }
     }
    }
    .CopyrightAreaRights {
      margin-top: 10px;
    }
    .CopyrightTextArea p, .CopyrightArea p {
      margin: 0;
      color: var(--emfe-w-color-gray-150, #828282);
      font-size: 9px;
    }
  }
  @media only screen and (max-width: 768px) {
    .Footer {
      display: flex;
      flex-direction: column;
      &Side {
        position: relative;
        margin-left: 14px;
        margin-top: 66px;
      }
      &Grid {
        padding: 0;
        &:last-child {
          padding-bottom: 50px;
        }
      }
    }
    .GridRow {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      gap: 15px;
        &:last-child {
          border: none;
        }
    }
  }

  .ItemLanguage {
    display: none;
  }

  #LanguageList {
    padding: 0;
    margin: 0;
  }

  .LanguageSelectorContainer {
    position: relative;
  }

  .LanguageSelectorOverlay {
    position: relative;
    font-size: 12px;
  }

  .DrawerButton {
    height: 30px;
    width: 150px;
    background: inherit;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    display: flex;
    justify-content: space-evenly;
    align-items: center;

    border-radius: 0px;
    border: 0;
    position: relative;
  }

  .LanguageDrawer {
    position:absolute;
    background: $color-white;
    width: 150px;
    bottom: 30px;
    padding: 6px 0;
    left: 7px;

    &.hidden {
      display: none;
    }
  }

  .LanguageFlag {
    margin-right: 8px;
    background: black;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    }

  .OverlayLanguageOption {
    justify-content: center;
    list-style: none;
    position: relative;
    height: 35px;
    padding: 0;
    justify-content: space-between;
    display: flex;
    flex-direction: row;

    img {
      position: absolute;
      z-index: 0;
      cursor: pointer;
      top: 6px;
      left: 12px;
    }

    a {
      text-decoration: none;
      color: black;
      display: inline-block;

      width: 150px;

      span {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 4px 0;
      }
    }
  }

</style>
