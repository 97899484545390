<svelte:options tag={null} />

<script lang="ts">
  import { onMount } from "svelte";

  import '@everymatrix/casino-footer-thumbnail';

  export let sponsorflag:string = '';
  export let paymentflag:string = '';
  export let vendorflag:string = '';
  export let helperflag:string = '';
  export let licenseflag:string = '';
  export let socialflag:string = '';
  export let target:string = '';
  export let displaycolumn:string = '';
  export let category:string = '';
  export let baseurl:string = '';
  export let lang:string = '';
  export let clientstyling:string = '';
  export let clientstylingurl:string = '';
  export let footertype:string = '';

  let footersponsors:Array<Object> = [];
  let footerpayment:Array<Object> = [];
  let footervendors:Array<Object> = [];
  let footerhelperlinks:Array<Object> = [];
  let footerlicenses:Array<Object> = [];
  let footersociallinks:Array<Object> = [];
  let customStylingContainer:HTMLElement;

  const messageHandler = (e:any) => {
    if (e.data && e.data.type === 'FooterData') {
      footersponsors = e.data.sponsorsRepeater;
      footerpayment = e.data.paymentMethodsRepeater;
      footervendors = e.data.gameVendorsRepeater;
      footerhelperlinks = e.data.helpLinksRepeater;
      footerlicenses = e.data.licensesRepeater;
      footersociallinks = e.data.socialLinksRepeater;
      
      if (category) {
        footerhelperlinks = footerhelperlinks.filter((item:any) => {
          return item.key == category;
        });
      }
      // casino-footer-v2 uses a different structure for the CMS data for the helper links
      if (footertype === "footersecondary") {
        footerhelperlinks = footerhelperlinks[0].value;
      }
    }
  }

  const setClientStyling = () => {
    let sheet:any = document.createElement('style');
    sheet.innerHTML = clientstyling;
    customStylingContainer.appendChild(sheet);
  }

  const setClientStylingURL = ():void => {
    let url:URL = new URL(clientstylingurl);
    let cssFile:HTMLElement = document.createElement('style');

    fetch(url.href)
      .then((res:any) => res.text())
      .then((data:any) => {
        cssFile.innerHTML = data

        setTimeout(() => { customStylingContainer.appendChild(cssFile) }, 1);
      });
  }

  onMount(() => {
    window.addEventListener('message', messageHandler, false);

    return () => {​​​
      window.removeEventListener('message', messageHandler);
    }​​​
  });

  $: clientstyling && customStylingContainer && setClientStyling();
  $: clientstylingurl && customStylingContainer && setClientStylingURL();
</script>

<div class="FooterSectionContainer" part="FooterSectionContainer" bind:this={customStylingContainer}>
  {#if sponsorflag && footersponsors.length}
    <div class="FooterSectionInnerContainer" part="FooterSectionInnerContainer">
      {#each footersponsors as sponsorData}
      <casino-footer-thumbnail
        sponsorurl={sponsorData.sponsorUrl}
        sponsorimage={sponsorData.sponsorImage}
        sponsortitle={sponsorData.sponsorTitle}
        {clientstyling}
        {clientstylingurl}
        linktype={sponsorData?.isExternalLink}
        target={sponsorData?.target}
        {baseurl}
        {lang}></casino-footer-thumbnail>
      {/each}
    </div>
  {:else if paymentflag && footerpayment.length}
  <div class="FooterSectionInnerContainer" part="FooterSectionInnerContainer">
    {#each footerpayment as paymentData}
      <casino-footer-thumbnail
        paymenturl={paymentData.paymentMethodUrl}
        paymentimage={paymentData.paymentMethodImage}
        paymenttitle={paymentData.paymentMethodTitle}
        {clientstyling}
        {clientstylingurl}
        linktype={paymentData?.isExternalLink}
        target={paymentData?.target}
        {baseurl}
        {lang}></casino-footer-thumbnail>
    {/each}
  </div>
  {:else if vendorflag && footervendors.length}
    <div class="FooterSectionInnerContainer" part="FooterSectionInnerContainer">
      {#each footervendors as footerData}
        <casino-footer-thumbnail
          vendorurl={footerData.gameVendorUrl}
          vendorimage={footerData.gameVendorImage}
          vendortitle={footerData.gameVendorTitle}
          {clientstyling}
          {clientstylingurl}
          linktype={footerData?.isExternalLink}
          target={footerData?.target}
          {baseurl}
          {lang}></casino-footer-thumbnail>
      {/each}
    </div>
  {:else if helperflag && footerhelperlinks.length}
    <div class="FooterSectionInnerContainer {displaycolumn == "true" ? "DisplayColumn" : ""}" part="FooterSectionInnerContainer">
      {#each footerhelperlinks as footerData}
        <casino-footer-thumbnail 
          helperlinkurl={footerData.helpLinkUrl}
          helperlinkimage={footerData.helpLinkImage}
          helperlinktitle={footerData.helpLinkTitle}
          {clientstyling}
          {clientstylingurl}
          {displaycolumn}
          linktype={footerData?.helpLinkType}
          target={footerData?.target}
          {baseurl}
          {lang}></casino-footer-thumbnail>
      {/each}
    </div>
  {:else if licenseflag && footerlicenses.length}
    <div class="FooterSectionInnerContainer" part="FooterSectionInnerContainer">
      {#each footerlicenses as footerData}
        <casino-footer-thumbnail
          licenseurl={footerData.licenseUrl}
          licenseimage={footerData.licenseImage}
          licensetitle={footerData.licenseTitle}
          {clientstyling}
          {clientstylingurl}
          linktype={footerData?.isExternalLink}
          target={footerData?.target}
          {baseurl}
          {lang}></casino-footer-thumbnail>
      {/each}
    </div>
  {:else if socialflag && footersociallinks.length}
    <div class="FooterSectionInnerContainer" part="FooterSectionInnerContainer">
      {#each footersociallinks as footerData}
        <casino-footer-thumbnail 
          sociallinkurl={footerData.socialLinkUrl}
          sociallinkimage={footerData.socialLinkImage}
          sociallinktitle={footerData.socialLinkTitle}
          {clientstyling}
          {clientstylingurl}
          linktype={footerData?.isExternalLink}
          target={footerData?.target}
          {baseurl}
          {lang}></casino-footer-thumbnail>
      {/each}
    </div>
  {/if}
</div>

<style lang="scss">
  :host {
    font-family: system-ui, -apple-system, Roboto, Helvetica;
  }

  .FooterSectionTitle {
    font-weight: 700;
    font-size: 16px;
  }
  .FooterSectionInnerContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .DisplayColumn {
    flex-direction: column;
    align-items: flex-start;
  }
</style>
