export const TRANSLATIONS = {
  "en": {
    "timeText": "Time",
    "sessionTimer": "logged in for",
    "panicButton": "Hold button for 3 seconds to take 24-hour timeout"
  },
  "de": {
    "timeText": "Time",
    "sessionTimer": "logged in for",
    "panicButton": "Button für 3 Sekunden drücken um eine 24-stündige Auszeit zu nehmen"
  },
  "fr": {
    "timeText": "Time",
    "sessionTimer": "logged in for",
    "panicButton": "Hold button for 3 seconds to take 24-hour timeout"
  },
  "ro": {
    "timeText": "Ora",
    "sessionTimer": "online de",
    "panicButton": "Hold button for 3 seconds to take 24-hour timeout"
  },
  "es": {
    "timeText": "Hora",
    "sessionTimer": "tiempo sesión",
    "panicButton": "Dar clic durante 3 segundos para tomar un descanso de 24 horas"
  },
  "pt": {
    "timeText": "Time",
    "sessionTimer": "logged in for",
    "panicButton": "Hold button for 3 seconds to take 24-hour timeout"
  },
  "hr": {
    "timeText": "Time",
    "sessionTimer": "logged in for",
    "panicButton": "Držite gumb 3 sekunde kako biste aktivirali vremensko ograničenje od 24 sata"
  },
  "sl": {
    "timeText": "Time",
    "sessionTimer": "logged in for",
    "panicButton": "Držite gumb 3 sekunde, da bi vzeli 24 urni premor"
  },
  "pl": {
    "timeText": "Time",
    "sessionTimer": "logged in for",
    "panicButton": "Przytrzymaj przycisk przez 3 sekundy, aby uzyskać 24-godzinny limit czasu"
  }
}