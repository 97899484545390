<svelte:options tag={null} />
<script lang="ts">
  import { onMount } from 'svelte';
  import { getDevice } from 'rvhelper';
  import { _, addNewMessages, setLocale } from './i18n';
  import { TRANSLATIONS } from './translations';

  export let lang:string = 'en';
  export let customtext:string = '';
  export let clientstyling:string = '';
  export let clientstylingurl:string = '';
  export let alternativestyling:string = '';
  export let translationurl:string = '';

  let panicButton:HTMLElement;
  let customStylingContainer:HTMLElement;
  let panicLoading:boolean = false;
  let timer: number = 0;
  let timerInterval:any;
  let userAgent:any = window.navigator.userAgent;

  const setTranslationUrl = ():void => {
    let url:string = translationurl;

    fetch(url).then((res:any) => res.json())
    .then((res) => {
      Object.keys(res).forEach((item:any):void => {
        addNewMessages(item, res[item]);
      });
    }).catch((err:any) => {
      console.log(err);
    });
  }

  Object.keys(TRANSLATIONS).forEach((item) => {
    addNewMessages(item, TRANSLATIONS[item]);
  });

  const addEventsToDisplayedElements = () => {
      panicButton?.addEventListener("mousedown", startInterval, false);
      panicButton?.addEventListener('touchstart', startInterval, false);

      // on mouseup stop interval count
      panicButton?.addEventListener("mouseup", endInterval, false);
      panicButton?.addEventListener("touchend", endInterval, false);
  }

  const removeEventsToDisplayedElements = ():void => {
    panicLoading = false;
    panicButton?.removeEventListener("mousedown", startInterval);
    panicButton?.removeEventListener('touchstart', startInterval);

    // on mouseup stop interval count
    panicButton?.removeEventListener("mouseup", endInterval);
    panicButton?.removeEventListener("touchend", endInterval);
  }

  const startInterval = ():void => {
    timer = 0;
    panicLoading = true;

    timerInterval = setInterval(() => {
      timer++;
      if (timer >= 2) {
        window.postMessage({type: 'PanicButtonClicked'}, window.location.href);
        clearInterval(timerInterval);

        //Analytics event
        if(typeof gtag == 'function'){
          gtag('event', 'PanicButtonClicked', {
            'context': 'PanicButton'
          });
        }
      }
    }, 1000);
  }

  const endInterval = ():void => {
    panicLoading = false;
    clearInterval(timerInterval);
  }

  const setClientStyling = ():void => {
    let sheet = document.createElement('style');
    sheet.innerHTML = clientstyling;
    customStylingContainer.appendChild(sheet);
  }

  const setClientStylingURL = ():void => {
    let url:URL = new URL(clientstylingurl);
    let cssFile:HTMLElement = document.createElement('style');

    fetch(url.href)
      .then((res:any) => res.text())
      .then((data:any) => {
        cssFile.innerHTML = data

        setTimeout(() => { customStylingContainer.appendChild(cssFile) }, 1);
      });
  }

  onMount(() => {
    return () => {
        removeEventsToDisplayedElements();
    }
  });


  $: panicButton && addEventsToDisplayedElements();
  $: lang && setLocale(lang);
  $: clientstyling && customStylingContainer && setClientStyling();
  $: clientstylingurl && customStylingContainer && setClientStylingURL();
  $: translationurl && setTranslationUrl();
</script>

  <div class="{alternativestyling == 'true' ? "PanicButtonV2" : ""}"  bind:this={customStylingContainer}>
    <div class="PanicSection {(getDevice(userAgent) !== 'PC') ? 'PanicSectionMobile' : ''}" part="PanicSection {(getDevice(userAgent) !== 'PC') ? 'PanicSectionMobile' : ''}">
      <button 
        class="PanicButton {(getDevice(userAgent) !== 'PC') ? 'PanicButtonMobile' : ''}"
        class:PanicButtonAnimation={panicLoading}
        part="PanicButton {(getDevice(userAgent) !== 'PC') ? 'PanicButtonMobile' : ''}"
        bind:this={panicButton}>
        {customtext || $_('panicButtonString')}
      </button>
    </div>
  </div>


<style lang="scss">


  $color-primary: #D0046C;
  $button-width: 280px;

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
  }

  .PanicSection {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0;

    .PanicButton {
      border-radius: 5px;
      border: 1px solid $color-primary;
      background-color: $color-primary;
      font-size: 10px;
      width: $button-width;
      height: fit-content;
      padding: 15px;
      color: var(--emfe-w-color-white, #FFFFFF);
      cursor: pointer;
    }
  }

  // the use of !important is necessary in order to override margin from .PanicSection
  .PanicSectionMobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 20px 0 0 0 !important;
  }

  .PanicButtonMobile {
    border-radius: 5px;
    margin: 0 10px;
    border: 1px solid $color-primary;
    background-color: $color-primary;
    width: 100% !important;
    height: 30px;
    color: var(--emfe-w-color-white, #FFFFFF);
    cursor: pointer;

    //Remove text selection for panic button on mobile
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    // End of removing selection properties
  }

  .PanicButtonAnimation {
    background: -webkit-linear-gradient(
        135deg,
        rgba(20, 20, 20, 0) 55%, rgba(20, 20, 20, 0.3) 100%
    );

    background: -moz-linear-gradient(
        135deg,
        rgba(20, 20, 20, 0) 55%, rgba(20, 20, 20, 0.3) 100%
    );

    background: -o-linear-gradient(
        135deg,
        rgba(20, 20, 20, 0) 55%, rgba(20, 20, 20, 0.3) 100%
    );

    background-color: $color-primary;
    width: $button-width;
    color: #fff;

    -webkit-animation: bar-animation 2s linear;
  }

  @-webkit-keyframes bar-animation {
    0% {
      background-position: 0;
    }
    100% {
      background-position: $button-width;
    }
  }

  .PanicButtonV2{

    $color-primary:  #F57F17;
    $button-width: 388px;

    .PanicButton {
      border: 1px solid  $color-primary;
      background-color:  $color-primary;
      width: $button-width;
      font-size: 12px;
    }
    .PanicButtonMobile {
      border: 1px solid $color-primary;
      background-color:  $color-primary;
      font-size: 10px;
    }
    .PanicButtonAnimation {
      background-color:  $color-primary;
      width: $button-width;
    }

    @-webkit-keyframes bar-animation {
    0% {
      background-position: 0;
    }
    100% {
      background-position: $button-width;
    }
  }
  }
</style>
